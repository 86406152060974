import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { TimeFormat } from './time-format.model';

@Injectable({
  providedIn: 'root',
})
export class TimeFormatService {
  /**
   * Gets the time format information.
   */
  getTimeFormatInfo(): TimeFormat {
    const formattedTime = new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    }).resolvedOptions();

    const hour12 = formattedTime.hour12 ?? true;
    const hour = formattedTime.hour ?? 'numeric';
    const minute = formattedTime.minute ?? '2-digit';

    return {
      hour12: hour12,
      hour: hour,
      minute: minute,
      format: this.getTimeFormatPattern(hour12, hour, minute),
    } as TimeFormat;
  }

  /**
   * Gets the time format pattern.
   * @param {boolean} hour12 - The hour12 value.
   * @param {string} hour - The hour value.
   * @param {string} minute - The minute value.
   * @returns {string} - The time format pattern.
   */
  private getTimeFormatPattern(
    hour12: boolean,
    hour: string,
    minute: string
  ): string {
    const hourPart = hour12
      ? hour === '2-digit'
        ? 'hh'
        : 'h'
      : hour === '2-digit'
      ? 'HH'
      : 'H';

    const minutePart = minute === '2-digit' ? 'mm' : 'm';

    const periodPart = hour12 ? ' a' : '';

    return `${hourPart}:${minutePart}${periodPart}`;
  }
}
