import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TimeFormatService } from '../services/time-format.service';
import { min } from 'rxjs';

@Pipe({
  name: 'timeFormat',
  standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
  constructor(
    private timeFormatService: TimeFormatService,
    private datePipe: DatePipe
  ) {}

  transform(hours: number, minutes: string): string | undefined {
    const timeFormatInfo = this.timeFormatService.getTimeFormatInfo();
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(parseInt(minutes));

    return this.datePipe.transform(date, timeFormatInfo.format)?.toString();
  }
}
