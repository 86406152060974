import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TranslocoService } from '@jsverse/transloco';
import { Observable, map, of } from 'rxjs';

import { OpeningTime, WorkingHours } from '../model/working-hours';
import { TimeFormatService } from '../services/time-format.service';

@Pipe({
  name: 'workingHours',
  standalone: true,
})
export class WorkingHoursPipe implements PipeTransform {
  private workingTimeInfo = '';
  private readonly daysOfWeek: string[] = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
  ];
  private readonly keys = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'open-until',
    'closed-opens-on',
    'at',
  ];

  constructor(
    private translocoService: TranslocoService,
    private timeFormatService: TimeFormatService,
    private datePipe: DatePipe
  ) {}

  transform(workingHours: WorkingHours): Observable<string> {
    return this.translocoService.selectTranslate(this.keys).pipe(
      map((translations) => {
        this.workingTimeInfo = '';
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        const currentHours = currentDate.getHours();
        const currentMinutes = currentDate.getMinutes();

        let currentDayName = this.daysOfWeek[currentDay];
        const entry = workingHours.openingTimes[currentDayName];

        currentDayName =
          translations[this.getIndex(currentDayName.toLocaleLowerCase())];

        if (entry) {
          const shouldFind = this.shouldFindNextWorkingDay(
            entry,
            currentHours,
            currentMinutes,
            currentDayName,
            translations
          );

          if (shouldFind) {
            this.setAvailability(workingHours, currentDay, translations);
          }
        } else {
          this.workingTimeInfo +=
            translations[this.getIndex('closed-opens-on')] + ' ';
          this.setAvailability(workingHours, currentDay, translations);
        }

        return this.workingTimeInfo;
      })
    );
  }

  /**
   * Gets an index.
   * @param {string} key - A key.
   */
  private getIndex(key: string): number {
    return this.keys.findIndex((val) => val === key);
  }

  /**
   *  Check id the next working day should be found.
   * @param {OpeningTime} time  - The opening time.
   * @param {number} hours - Hours
   * @param {number} minutes - Minutes
   * @param {string} currentDayName - The name of current day.
   * @param { { [key: number]: string }} translations - Translations.
   * @returns {booelan} - True if the next working day should be found.
   */
  private shouldFindNextWorkingDay(
    time: OpeningTime,
    hours: number,
    minutes: number,
    currentDayName: string,
    translations: { [key: number]: string }
  ): boolean {
    if (
      time.from.hours * 60 + time.from.minutes <= hours * 60 + minutes &&
      time.to.hours * 60 + time.to.minutes >= hours * 60 + minutes
    ) {
      const info =
        translations[this.getIndex('open-until')] +
        ' ' +
        this.getFormattedTime(time.to.hours, time.to.minutes);
      this.workingTimeInfo += info;
      return false;
    } else if (
      time.from.hours > hours ||
      (time.from.hours === hours && time.from.minutes > minutes)
    ) {
      const info =
        translations[this.getIndex('closed-opens-on')] +
        ' ' +
        currentDayName +
        ' ' +
        translations[this.getIndex('at')] +
        ' ' +
        this.getFormattedTime(time.from.hours, time.from.minutes);
      this.workingTimeInfo += info;
      return false;
    } else {
      this.workingTimeInfo +=
        translations[this.getIndex('closed-opens-on')] + ' ';
      return true;
    }
  }

  /**
   *  Sets availability.
   * @param {WorkingHours} workingHours - Working hours.
   * @param {number} currentDay - An index of the current day.
   * @param {{ [key: number]: string }} translations - Translations.
   */
  private setAvailability(
    workingHours: WorkingHours,
    currentDay: number,
    translations: { [key: number]: string }
  ): void {
    let stop = false;

    for (let i = currentDay + 1; i <= 6; i++) {
      const nextEntry = workingHours.openingTimes[this.daysOfWeek[i]];
      if (nextEntry) {
        this.setNextOpeningTime(nextEntry, i, translations);
        stop = true;
        break;
      }
    }

    if (!stop) {
      for (let i = 0; i <= currentDay; i++) {
        const nextEntry = workingHours.openingTimes[this.daysOfWeek[i]];
        if (nextEntry) {
          this.setNextOpeningTime(nextEntry, i, translations);
          break;
        }
      }
    }
  }

  /**
   * Sets the next opening time.
   * @param {OpeningTime}time - Opening time.
   * @param {number}nextDay - An index of the next day.
   * @param {{ [key: number]: string }} translations - Translations.
   */
  private setNextOpeningTime(
    time: OpeningTime,
    nextDay: number,
    translations: { [key: number]: string }
  ): void {
    this.workingTimeInfo +=
      translations[
        this.getIndex(this.daysOfWeek[nextDay].toLocaleLowerCase())
      ] +
      ' ' +
      translations[this.getIndex('at')] +
      ' ' +
      this.getFormattedTime(time.from.hours, time.from.minutes);
  }

  /**
   * Gets the formatted time.
   * @param {number} hours - Hours.
   * @param {number} minutes - Minutes.
   * @returns {string | undefined} - The formatted time.
   */
  private getFormattedTime(hours: number, minutes: number): string | undefined {
    const timeFormatInfo = this.timeFormatService.getTimeFormatInfo();
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    return this.datePipe.transform(date, timeFormatInfo.format)?.toString();
  }
}
