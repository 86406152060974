<div class="flex fd-column" *transloco="let t">
  <h3>{{ t("opening-times") }}</h3>
  <div class="d-flex fd-column gap-12 opening-times">
    @for (workingDay of workingDays; track workingDay) {
    <div
      class="flex day ai-center gap-12"
      [ngClass]="{
        closed: !workingDay.isWorkDay,
        'current-day': workingDay.isCurrentDay
      }"
    >
      <div class="bullet" [ngClass]="{ closed: !workingDay.isWorkDay }"></div>
      <div class="name">{{ workingDay.name }}</div>
      <div class="flex jc-end">
        {{
          workingDay.isWorkDay
            ? (workingDay.fromHours | timeFormat : workingDay.fromMinutes) +
              " - " +
              (workingDay.toHours | timeFormat : workingDay.toMinutes)
            : t("closed")
        }}
      </div>
    </div>
    }
  </div>
</div>
