import { Component, Input } from '@angular/core';
import { NgClass, NgFor } from '@angular/common';

import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { Subject, takeUntil } from 'rxjs';

import { WorkingDays } from '../../model/working-hours';
import { CompanyLocation } from '../../model/company-location';
import { TimeFormatPipe } from '../../pipes/time-format.pipe';

@Component({
  selector: 'app-opening-times',
  standalone: true,
  imports: [NgFor, NgClass, TranslocoDirective, TimeFormatPipe],
  templateUrl: './opening-times.component.html',
  styleUrl: './opening-times.component.scss',
})
export class OpeningTimesComponent {
  @Input() companyLocation!: CompanyLocation;

  workingDays: WorkingDays[] = [];
  private componentDestroyed$ = new Subject<void>();

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.setWorkingDays();
  }

  /**
   * Sets the working days.
   */
  private setWorkingDays(): void {
    const daysOfWeek = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
      'SUNDAY',
    ];

    const currentDay = this.getCurrentDay();

    this.translocoService
      .selectTranslate(
        daysOfWeek.map((dayOfWeek) => dayOfWeek.toLocaleLowerCase())
      )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((translations) => {
        this.workingDays = [];
        daysOfWeek.forEach((d, index) => {
          const workTime = this.companyLocation.workingHours.openingTimes[d];
          workTime
            ? this.workingDays.push({
                name: translations[index],
                isWorkDay: true,
                fromHours: workTime.from.hours,
                fromMinutes:
                  workTime.from.minutes === 0
                    ? '00'
                    : workTime.from.minutes.toString(),
                toHours: workTime.to.hours,
                toMinutes:
                  workTime.to.minutes === 0
                    ? '00'
                    : workTime.to.minutes.toString(),
                isCurrentDay: d === currentDay,
              })
            : this.workingDays.push({
                name: translations[index],
                isWorkDay: false,
                fromHours: 0,
                fromMinutes: '00',
                toHours: 0,
                toMinutes: '00',
                isCurrentDay: d === currentDay,
              });
        });
      });
  }

  /**
   * Gets the current day.
   * @returns {string} - The current day.
   */
  private getCurrentDay(): string {
    const today = new Date();
    const currentDayIndex = today.getDay(); // This will return a number from 0 (Sunday) to 6 (Saturday)

    // Convert the index to the name of the day
    const daysOfWeek = [
      'SUNDAY',
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
    return daysOfWeek[currentDayIndex];
  }
}
